import "./styles.css";
import axios from "axios";
import { useState, useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSearchGamesInfo,
  getSearchGamesList,
  getSearchGamesInfoPriceTr,
  getSearchGamesInfoPriceUsa,
  getSearchGamesInfoPriceUah,
  // getSearchGamesInfoPriceEg,
  // getSearchGamesInfoPriceNg,
  setSearchGamesList,
  setSearchGamesListEn,
  getSearchGamesListEn,
} from "../../../features/SearchSlice/SearchSlice.js";
import logo from "../../../img/logo.png";

import { getCarrencyCurSu } from "../../../features/CarrencyCurSuSlice/CarrencyCurSuSlice";
import GamePageUser from "../gamePageUser/index.jsx";
import CarrencyUser from "../CarrencyUser/index.jsx";
import ButtonHead from "../../buttonHead/index.jsx";

const SearchGameUser = () => {
  const dispatch = useDispatch();
  const searchGamesList = useSelector((state) => state.search.searchGamesList);
  const searchGamesListEn = useSelector(
    (state) => state.search.searchGamesListEn
  );

  const isFetchingGamesInfo = useSelector(
    (state) => state.search.isFetchingGamesInfo
  );
  const isFetchingPriceTr = useSelector(
    (state) => state.search.isFetchingPriceTr
  );

  const isFetchingPriceUsa = useSelector(
    (state) => state.search.isFetchingPriceUsa
  );
  const carrencyArCurSu = useSelector(
    (state) => state.carrencyCurSu.carrencyArCurSu
  );

  const [isSwitchInfo, setIsSwitchInfo] = useState(false);

  // const [language, setLanguage] = useState("en");

  const [searchUrl, setSearchUrl] = useState("");

  const [arr, setArr] = useState([]);

  const arrFilter = (searchGamesList, searchGamesListEn) => {
    const responseAll = [...searchGamesListEn, ...searchGamesList];

    const uniqueArray = responseAll.filter(
      (item, index, self) =>
        index ===
        self.findIndex((i) => i.Metas[0].Value === item.Metas[0].Value)
    );

    setArr(uniqueArray);
  };

  useEffect(() => {
    dispatch(getCarrencyCurSu());
    // dispatch(getCarrency());
  }, []);

  return (
    <div className="wrapper">
      <ButtonHead />
      <div className="search">
        <div className="search__title">Поиск цен для игр Xbox</div>
        <div className="input-wrapper">
          {/* <div className="language-wrapper">
            <div>Регион поиска</div>
            <select
              className="select-language"
              onChange={(e) => {
                setLanguage(e.target.value);
              }}
            >
              <option value="en">en</option>
              <option value="ru">ru</option>
            </select>
          </div> */}
          <div style={{ marginTop: "15px" }}>Поиск по названию</div>
          <input
            className="search-input"
            placeholder="Введите название игры"
            type="text"
            onChange={(e) => {
              // if (language === "ru") {
              dispatch(getSearchGamesList(e.target.value));
              dispatch(getSearchGamesListEn(e.target.value));
              // console.log(searchGamesList);
              // console.log(searchGamesListEn);
              // console.log(arr);

              arrFilter(searchGamesList, searchGamesListEn);
              // } else {
              //   dispatch(getSearchGamesListEn(e.target.value));
              // }
            }}
            onBlur={(e) => {
              setTimeout(() => ((e.target.value = ""), 3000));
            }}
          />

          <div className="search-list">
            {arr.map((game) => (
              <div
                key={game.Metas[0].Value}
                className="search-list__item"
                onClick={() => {
                  dispatch(getSearchGamesInfo(game.Metas[0].Value));
                  dispatch(getSearchGamesInfoPriceTr(game.Metas[0].Value));
                  // dispatch(getSearchGamesInfoPriceUah(game.Metas[0].Value));
                  dispatch(getSearchGamesInfoPriceUsa(game.Metas[0].Value));

                  // dispatch(getSearchGamesInfoPriceEg(game.Metas[0].Value));
                  // dispatch(getSearchGamesInfoPriceNg(game.Metas[0].Value));
                  // dispatch(setSearchGamesList([]));
                  // dispatch(setSearchGamesListEn([]));
                  setArr([]);
                  setIsSwitchInfo(true);

                  // setTimeout(() => dispatch(setSearchGamesList([])), 1000);
                }}
                onBlur={() => {
                  setArr([]);
                  dispatch(setSearchGamesList([]));
                  dispatch(setSearchGamesListEn([]));
                  // setTimeout(() => (setArr([]), 3000));
                }}
              >
                <img style={{ width: 40 }} src={game.Metas[2].Value} alt="" />
                <div>{game.Title}</div>
              </div>
            ))}
          </div>
        </div>

        {/* ============== поиск по ссылке */}
        <div className="input-wrapper">
          <div>Поиск по ссылке</div>
          <input
            className="search-input"
            type="text"
            placeholder="Вставьте ссылку из Store"
            onChange={(e) => setSearchUrl(e.target.value)}
            value={searchUrl}
          />
          <button
            className="btn__search"
            onClick={() => {
              if (searchUrl) {
                // const text = searchUrl.split("/").pop(); // работало раньше так
                const text = searchUrl.split("/")[7];
                // const text = searchUrl.split("/");
                // const textId = searchUrl.split("/").slice(0, 8).join("/");

                // console.log(searchUrl.split("/").slice(0, 8).join("/"));

                // console.log(textId.split("/").pop());
                // console.log(textId.split("/")[5]);
                // console.log(searchUrl.split("/")[7]);

                // dispatch(getSearchGamesInfo(text[5]));
                // dispatch(getSearchGamesInfoPriceTr(text[5]));

                dispatch(getSearchGamesInfo(text.split("?")[0]));
                dispatch(getSearchGamesInfoPriceTr(text.split("?")[0]));
                // dispatch(getSearchGamesInfoPriceUah(text.split("?")[0]));
                dispatch(getSearchGamesInfoPriceUsa(text.split("?")[0]));

                // dispatch(getSearchGamesInfoPriceEg(text.split("?")[0]));
                // dispatch(getSearchGamesInfoPriceNg(text.split("?")[0]));

                setIsSwitchInfo(true);
                setSearchUrl("");
              }
            }}
          >
            Поиск
          </button>
        </div>
        {/* ================ */}
      </div>
      {isSwitchInfo ? null : (
        <>
          <div className="gpoup-information">
            <div className="gpoup-information__text">
              Подробную информацию о ценах можно получить в группе
            </div>
            <img className="gpoup-information__logo" src={logo} />
            <a
              className="gpoup-information__title"
              href="https://vk.com/xbogames"
              target="_blank"
              rel="noopener noreferrer"
            >
              XBOGames
            </a>
          </div>
        </>
      )}
      <div className="game-info">
        {isFetchingPriceTr && isFetchingPriceUsa && isFetchingGamesInfo ? (
          <GamePageUser />
        ) : null}

        {isFetchingPriceTr && isFetchingPriceUsa && isFetchingGamesInfo ? (
          <CarrencyUser />
        ) : null}
      </div>
    </div>
  );
};

export default SearchGameUser;
